<template>
    <v-list class="gst-list pt-0">
        <v-list-item
            v-for="(item, index) in items"
            :key="index"
            class="gst-list__item px-0 py-4"
            :to="{name:'attraction', params: { id: item.id } }">
            <AttractionCardVariant2 :item="item" class="u-background-transparent u-width-100" />
        </v-list-item>
    </v-list>
</template>

<script>
    import AttractionCardVariant2 from '@tenant/app/components/attraction/AttractionCardVariant2.vue';

    export default {
        name: 'SearchResultTabsAttractionsList',
        components: {
            AttractionCardVariant2
        },
        props: {
            items: {
                type: Array,
                default: null
            }
        }
    };
</script>